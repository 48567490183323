import { createWebHistory, createRouter } from "vue-router";
import Tiles from "./components/Tiles.vue";
import Item from "./components/Item.vue";
import WeeklyTable from "./components/WeeklyTable.vue";


const routes = [
	{
		path: "/",
		name: "Home",
		component: Tiles,
	},
	{
		path: "/item/:id",
		name: "Item",
		component: Item,
	},
	{
		path: "/weekly",
		name: "Weekly",
		component: WeeklyTable,
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
