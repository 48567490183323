<template>
	<div>
		<div class="header-parent">
			<div class="header-child">
				<img height="300px" style="margin: 15px;box-sizing: border-box" src="../static/logo.png">
			</div>
			<div class="header-child">
				<h1 style="color:white">7 Day price change of CSGO cases</h1>
				<h2 style="color:white">Weighted by 12 month trading volume</h2>
				<h3 style="text-align: center;color:red;">New: click tiles to see "For Sale" history</h3>
				<a href="/weekly">
					<h4 style="width:100%;text-align:center;" color="red">Table</h4>
				</a>
			</div>
		</div>
	</div>
	<div id="d3_container" style="position: relative"></div>
</template>

<script lang="ts">
import * as d3 from 'd3';
import * as placeholderData from '../static/placeholder_data.json';

function lerpColor(a, b, amount) {

	let ah = parseInt(a.replace(/#/g, ''), 16),
		ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
		bh = parseInt(b.replace(/#/g, ''), 16),
		br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
		rr = ar + amount * (br - ar),
		rg = ag + amount * (bg - ag),
		rb = ab + amount * (bb - ab);

	return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

export default {

	async mounted() {

		const margin = { top: 0, right: 0, bottom: 0, left: 0 }, width = window.innerWidth
		const height = window.innerHeight

		const svg = d3.select("#d3_container")
			.append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left}, ${margin.top})`);

		let _data;
		if (window.location.hostname === 'localhost') {
			_data = placeholderData;
		} else {
			_data = await (await fetch('https://api.csgostonks.io/daily.php')).json();
		}

		const data = { "children": [] };
		data.children = _data.map(x => {
			return {
				name: x.name,
				value: parseInt(x.total_usd_volume),
				price: x.performance.PRICE_NOW,
				performance: x.performance.PRICE_CHANGE_PCT
			}
		})

		const root = d3.hierarchy(data).sum(function (d) {
			return d.value
		})

		d3.treemap()
			.size([width, height])
			.padding(0)(root)

		const all = [];

		svg
			.selectAll("rect")
			.data(root.leaves())
			.join("rect")
			.attr('x', function (d) {
				return d.x0;
			})
			.attr('y', function (d) {
				return d.y0;
			})
			.attr('width', function (d) {
				return d.x1 - d.x0;
			})
			.attr('height', function (d) {
				return d.y1 - d.y0;
			})
			.attr('class', 'treemap_element')
			.style("stroke", "black")
			.style("fill", d => {
				all.push(d);

				if (d.data.performance < -10)
					return '#B51700'
				if (d.data.performance > 10)
					return '#047101'
				if (d.data.performance < 0)
					return lerpColor('#ED7171', '#B51700', d.data.performance / -10);
				if (d.data.performance > 0)
					return lerpColor('#c9e7c9', '#047101', d.data.performance / 10);
				return '#C9E7C9FF';
			})

		for (let i = 0; i < all.length; i++) {
			const element = all[i];

			const width = parseInt(element.x1) - parseInt(element.x0);
			const height = parseInt(element.y1) - parseInt(element.y0)
			const $newDiv = $('<div>').css({
				position: 'absolute',
				top: parseInt(element.y0),
				left: parseInt(element.x0),
				width,
				height,
				cursor: 'pointer'
			});
			const $flexbox = $(`<div class="flex-container"> </div> `);
			const $row = $("<div class='row'>");

			const $name = $("<div>").text(element.data.name);

			const $price = $("<div>").text(element.data.price);
			const $percentage = $("<div>").text(element.data.performance + '%')

			$row.append($name);
			$row.append($price);
			$row.append($percentage)

			const smallestEdge = Math.min(width, height)
			if (smallestEdge < 200 && (15 * (smallestEdge / 100)) < 20) {
				const newFontSize = `calc(15px * (${smallestEdge}/100))`
				$name.css({ fontSize: newFontSize });
				$price.css({ fontSize: newFontSize });
				$percentage.css({ fontSize: newFontSize });
			}

			$flexbox.append($row)
			$newDiv.append($flexbox);
			$("#d3_container").append($newDiv);


			//make all except newdiv click-through
			$('.treemap_element').not($newDiv).css('pointer-events', 'none');
			//on click, create an image overlay
			$newDiv.click(function () {
				const $overlay = $('<div>').css({
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0,0,0,0.5)',
					zIndex: 1000
				});
				//add image
				const $img = $('<img>').css({
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					maxWidth: '90%',
					maxHeight: '90%',
					zIndex: 1001
				}).attr('src', `https://api.csgostonks.io/item.php?name=${encodeURIComponent(element!.data.name)}&timestamp=${new Date().getTime()}`);

				//below the image create a button to get to the item on steamcommunity market
				const $button = $(`<a href="https://steamcommunity.com/market/listings/730/${element.data.name}" target="_blank" class="btn btn-primary">View on Steam Community Market</a>`);
				$button.css({
					position: 'absolute',
					bottom: '-10px',
					left: '50%',
					maxHeight: '5%',
					transform: 'translate(-50%, -50%)',
					background: 'rgba(0,0,0,0.9)',
					borderRadius: '10px',
					fontSize: '8px',
					padding: '10px',
					zIndex: 1002
				});
				$overlay.append($button);

				//if button is clicked, don't remove overlay
				$button.click(function (e) {
					e.stopPropagation();
				});

				$overlay.append($img);
				$('body').append($overlay);
				//on click, remove overlay
				$overlay.click(function () {
					$overlay.remove();
				});
				//on escape key, remove overlay
				$(document).keyup(function (e) {
					if (e.key === "Escape") {
						$overlay.remove();
					}
				});

			});
		}

	},
	data() {
		return {};
	},
};
</script>
