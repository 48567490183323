<template><router-view></router-view></template>

<script lang="ts">
import Tiles from './components/Tiles.vue';
import WeeklyTable from './components/WeeklyTable.vue';
import NotFound from './components/NotFound.vue';
import Feedback from './components/Feedback.vue';

const routes = {
	'/': Tiles,
	'/weekly': WeeklyTable
}

export default {
	data() {
		return {
			currentPath: window.location.hash
		}
	},
	components: {
		Feedback
	},
	mounted() {
		window.addEventListener('hashchange', () => {
			this.currentPath = window.location.hash
		})
	}

};
</script>
