<template>
	<div id="feedback-container" :class="expanded ? '' : 'collapsed'">
		<p @click="expanded = !expanded" v-if="!expanded">Feedback or feature request? Click here to let me know</p>
		<template v-if="expanded">
			<!-- feedback input area -->
			<textarea id="feedbackTextArea" placeholder="Tell me what's on your mind"></textarea>
			<div class="form-button-container">
				<button @click="onCancel" class="cancel">Cancel</button>
				<button @click="onSubmit" class="submit">Submit</button>
			</div>
		</template>
</div>
</template>

<style>
/* evenly space all buttons in form-button-container */
.form-button-container {
	display: flex;
	justify-content: space-between;
}

#feedbackTextArea {
	border: none;
	border-radius: 5px;
	color: white;
	background: rgba(255, 255, 255, 0.2);
}

#feedbackTextArea:focus {
	outline: none;
}

#feedbackTextArea::placeholder {
	color: whitesmoke;
}

.cancel {
	background-color: tomato;
	color: black;
	border: none;
	border-radius: 5px;
	;
}

.submit {
	background-color: palegreen;
	color: black;
	border: none;
	border-radius: 5px;
}

/* make buttons 50% width each */
.form-button-container button {
	cursor: pointer;
	width: calc(50% - 5px);
}

/* top right corner, wiggling animation */
#feedback-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 300px;
	height: 200px;
	background-color: rgba(255, 0, 255, 0.8);
	z-index: 1000;
}

#feedback-container.collapsed {
	width: 300px;
	height: 100px;
	/* wiggle animation, random delay */
	animation: wiggle 10s ease-in-out infinite;
}

#feedback-container p {
	color: black;
	font-weight: bold;
	text-align: center;
	padding: 15px;
	cursor: pointer;
	font-size: 13px;
}

/* default, don't expand */
#feedback-container textarea {
	width: 100%;
	height: 100%;
}

/* wiggle every couple seconds */
@keyframes wiggle {
	0% {
		transform: rotate(0deg);
	}

	10% {
		transform: rotate(10deg);
	}

	20% {
		transform: rotate(0deg);
	}

	80% {
		transform: rotate(0deg);
	}

	90% {
		transform: rotate(-10deg);
	}

	100% {
		transform: rotate(0deg);
	}
}
</style>

<script lang="ts">
const feedbackUrl = 'https://api.csgostonks.io/send-message'
export default {
	async mounted() {

	},
	data() {
		return {
			expanded: false
		}
	},
	methods: {
		onCancel() {
			this.expanded = false
		},
		onSubmit() {
			//call fetch post
			const feedback = document.getElementById('feedbackTextArea')!.value
			console.log(feedback)
			fetch(feedbackUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ message: feedback })
			})
				.then(res => res.json())
				.then(data => {
					console.log(data)
					this.expanded = false
				})
				.catch(err => console.error(err))
		},
		onExpand() {
			this.expanded = true
			//focus textareaa next tick
			setTimeout(() => {
				const textarea = document.getElementById('feedbackTextArea')
				if (!textarea) return;
				textarea.focus()
			}, 0)
		},
		sendFeedback() {


		}
	}
}
</script>