<template>
  <div>
    <p>
      sup
    </p>
    <LineChart
        :data="data"
        :labels="labels"
        :options="options">
    </LineChart>

  </div>
</template>

<style scoped>
p {
  color: black;
}
</style>

<script lang="ts">
import { Line } from "vue-chartjs";

export default {
  components: {
    Line
  },
  data() {
    return {
      data: [],
      labels: [],
      options: {}
    }
  }
};
</script>
